export const Actiontypes = {
    FETCH_LOGIN_CRED: "FETCH_LOGIN_CRED",
    APP_SIDEBAR_SHOW: "set",
    FETCH_EMP_PROFILE_DATA: "FETCH_EMP_PROFILE_DATA",

    //For the each employee prifile listing in the employee record list
    FETCH_ACADEMIC_DATA: "FETCH_ACADEMIC_DATA",
    FETCH_EXPERIENCE_DATA: "FETCH_EXPERIENCE_DATA",
    FETCH_WAGE_DATA: "FETCH_WAGE_DATA",
    FETCH_LEAVE_DATA: "FETCH_LEAVE_DATA",
    FETCH_EMP_PERSONAL_INFOM: "FETCH_EMP_PERSONAL_INFOM",
    FETCH_DEPT_SHIFT_DATA: "FETCH_DEPT_SHIFT_DATA",
    FETCH_EMP_LEAVE_LIST: "FETCH_EMP_LEAVE_LIST",
    FETCH_DEPARTMENT_LIST: "FETCH_DEPARTMENT_LIST",//department list dropdown
    FETCH_DEPARTSEC_LIST: "FETCH_DEPARTSEC_LIST",//dept section list dropdown
    FETCH_LEAVE_AVAIL_LIST: "FETCH_LEAVE_AVAIL_LIST",
    FETCH_NOTIFYDETL: "FETCH_NOTIFYDETL",

    //For the employee record list in the Employee File From
    FETCH_EMP_RECORD_LIST: "FETCH_EMP_RECORD_LIST",

    //for getting employee details in duty plan
    FETCH_EMP_DETAILS: "FETCH_EMP_DETAILS",
    FETCH_ALERT_MESSAGE: "FETCH_ALERT_MESSAGE",
    FETCH_EMP_MESSAGE: "FETCH_EMP_MESSAGE",
    FETCH_ANNOUNCEMENT_MESSAGE: "FETCH_ANNOUNCEMENT_MESSAGE",
    GET_MODULE_RIGHTS: "GET_MODULE_RIGHTS",
    FETCH_EMP_LEAVE_PROCESS_DETL: "FETCH_EMP_LEAVE_PROCESS_DETL",
    FETCH_EMP_LEAVE_PROCESS_DETL_DEFAULT: "FETCH_EMP_LEAVE_PROCESS_DETL_DEFAULT",
    FETCH_REGION_DATA: "FETCH_REGION_DATA",
    FETCH_PROFFESSIONAL_TAX: "FETCH_PROFFESSIONAL_TAX",
    FETCH_JOB_DESCRIPTION: "FETCH_JOB_DESCRIPTION",
    //for getting employee bloodgroup
    FETCH_EMP_BLOODGROUP: "FETCH_EMP_BLOODGROUP ",
    FETCH_EMP_DEPTSECT: "FETCH_EMP_DEPTSECT ",
    //for getting employee district wise region
    FETCH_EMP_DISTREGION: "FETCH_EMP_DISTREGION",
    //to get employee district
    FETCH_EMP_DISTRICT: "FETCH_EMP_DISTRICT",
    //to get employee name
    FETCH_EMP_NAME: "FETCH_EMP_NAME",
    //to get employee religion
    FETCH_EMP_RELIGION: "FETCH_EMP_RELIGION",
    FETCH_CHANGE_STATE: "FETCH_CHANGE_STATE",
    FETCH_CONTRACT_DETL: "FETCH_CONTRACT_DETL",
    FETCH_SALARY_DETL: "FETCH_SALARY_DETL",
    // to get employee institution type
    FETCH_EMP_INSTITUTION: "FETCH_EMP_INSTITUTION",
    FETCH_EMP_CATEGORY: "FETCH_EMP_CATEGORY",
    FETCH_EMP_DESIGNATION: "FETCH_EMP_DESIGNATION",
    FETCH_EMP_EDUCATION: "FETCH_EMP_EDUCATION",
    FETCH_EMP_COURSE: "FETCH_EMP_COURSE",
    FETCH_EMP_SPECILIZATION: "FETCH_EMP_SPECILIZATION",

    // Dash Board Alert Reducer
    FETCH_RESIGN_COUNT: "FETCH_RESIGN_COUNT",
    FETCH_CONTRACT_CLOSE: "FETCH_CONTRACT_CLOSE",
    FETCH_OVERTIME_COUNT: "FETCH_OVERTIME_COUNT",
    FETCH_OVERTIME_INCHARGE: "FETCH_OVERTIME_INCHARGE",
    FETCH_OVERTIME_COUNT_HOD: "FETCH_OVERTIME_COUNT_HOD",
    FETCH_OVERTIME_COUNT_CEO: "FETCH_OVERTIME_COUNT_CEO",
    FETCH_OVERTIME_COUNT_USER: "FETCH_OVERTIME_COUNT_USER",
    FETCH_LEAVE_REQ_COUNT_INCHARGE: "FETCH_LEAVE_REQ_COUNT_INCHARGE",
    FETCH_LEAVE_REQ_COUNT_HOD: "FETCH_LEAVE_REQ_COUNT_HOD",
    FETCH_LEAVE_REQ_COUNT_CEO: "FETCH_LEAVE_REQ_COUNT_CEO",
    FETCH_GET_LEAVE_REQ_COUNT_HR: "FETCH_GET_LEAVE_REQ_COUNT_HR",
    FETCH_LEAVE_REQ_COUNT_USER: "FETCH_LEAVE_REQ_COUNT_USER",
    FETCH_RESIGN_REQ_COUNT_INCHARGE: "FETCH_RESIGN_REQ_COUNT_INCHARGE",
    FETCH_RESIGN_REQ_COUNT_HOD: "FETCH_RESIGN_REQ_COUNT_HOD",
    FETCH_RESIGN_REQ_COUNT_CEO: "FETCH_RESIGN_REQ_COUNT_CEO",
    FETCH_CONTRACT_RENEW_COUNT: "FETCH_CONTRACT_RENEW_COUNT",
    FETCH_TRAIN_COUNT: "FETCH_TRAIN_COUNT",
    FETCH_REGISTER_RENEW: "FETCH_REGISTER_RENEW",
    FETCH_EMP_ACTIVECOUNT: "FETCH_EMP_ACTIVECOUNT",
    FETCH_EMP_PUNCHCOUNT: "FETCH_EMP_PUNCHCOUNT",
    FETCH_PROBATION: "FETCH_PROBATION",
    FETCH_ANNUAL: "FETCH_ANNUAL",

    //employee registration type
    FETCH_EMP_REGISTRATION_TYPE: "FETCH_EMP_REGISTRATION_TYPE",
    FETCH_EMP_BRANCH: "FETCH_EMP_BRANCH",
    FETCH_EMP_SUBSECTION: "FETCH_EMP_SUBSECTION",
    FETCH_EMP_SECTIONTYPE: "FETCH_EMP_SECTIONTYPE",
    FETCH_EMP_TRAININPROBA: "FETCH_EMP_TRAININPROBA",

    //MENU LIST SLNO TYPE
    FETCH_EMP_MENU_SLNO: "FETCH_EMP_MENU_SLNO",

    FETCH_EMP_BIRTHDAY: "FETCH_EMP_BIRTHDAY",
    FETCH_GRADE_LIST: "FETCH_GRADE_LIST",

    FETCH_CONTRACT_CLOSE_DATA: "FETCH_CONTRACT_CLOSE_DATA",
    FETCH_CONT_CLOSE_ATTENDANCE: "FETCH_CONT_CLOSE_ATTENDANCE",
    FETCH_CONTRACT_ARREAR: "FETCH_CONTRACT_ARREAR",
    FETCH_OLD_DATA_TO_COPY: "FETCH_OLD_DATA_TO_COPY",
    FETCH_OLD_PERSONAL_DATA: "FETCH_OLD_PERSONAL_DATA",
    FETCH_OLD_QUALIFICATION: "FETCH_OLD_QUALIFICATION",
    FETCH_OLD_EXPERIENCE: "FETCH_OLD_EXPERIENCE",
    FETCH_OLD_SALARYINFORM: "FETCH_OLD_SALARYINFORM",
    FETCH_DEPARTMENT: "FETCH_DEPARTMENT",
    //FOR SET  THE NEW CATGEOTY FROM CONTR-RENEW FORM
    FETCH_NEW_CATEGORY: "FETCH_NEW_CATEGORY",
    FETCH_NEW_CAT: "FETCH_NEW_CAT",
    //FOR GETTING DEPARTMENT WISE DEPARTMENT SECTION
    FETCH_DEPARTMENTSECTION: "FETCH_DEPARTMENTSECTION",

    //for getting shift hours from databse
    FETCH_SHIFT_DATA: "FETCH_SHIFT_DATA",

    //for getting the Common Settig 
    FETCH_COMMON_SETTING: "FETCH_COMMON_SETTING",
    //FETCH_LEAVES TABLE DATA 
    FETCH_CASUAL_LEAVE_DATA: "FETCH_CASUAL_LEAVE_DATA",
    //for job Description details
    FETCH_JOB_SUMMARY: "FETCH_JOB_SUMMARY",
    FETCH_JOB_DUTIES: "FETCH_JOB_DUTIES",
    FETCH_JOB_PERFORMANCE: "FETCH_JOB_PERFORMANCE",
    FETCH_JOB_COMPETENCY: "FETCH_JOB_COMPETENCY",
    FETCH_JOB_GENERIC: "FETCH_JOB_GENERIC",
    FETCH_JOB_QUALIFICATION: "FETCH_JOB_QUALIFICATION",

    FETCH_HOLIDAY_LIST: "FETCH_HOLIDAY_LIST",
    FETCH_COMMON__LIST: "FETCH_COMMON_LIST",

    FETCH_HOLIDAY_LEAVE_LIST: "FETCH_HOLIDAY_LEAVE_LIST",
    FETCH_COMMON_LEAVE_LIST: "FETCH_COMMON_LEAVE_LIST",
    FETCH_CASUAL_LEAVE_LIST: "FETCH_CASUAL_LEAVE_LIST",
    FETCH_PRIVILEGE_LEAVE_LIST: "FETCH_PRIVILEGE_LEAVE_LIST",
    FETCH_CARRY_FORWARD_LEAVE_LIST: "FETCH_CARRY_FORWARD_LEAVE_LIST",
    FETCH_CREDIT_LEAVE_LIST: "FETCH_CREDIT_LEAVE_LIST",

    UPDATE_CASUAL_LEAVE: "UPDATE_CASUAL_LEAVE",
    //for performance appraisal
    FETCH_HOD_APPRAISAL_COUNT: "FETCH_HOD_APPRAISAL_COUNT", //only for hod appraisal count
    FETCH_INCHARGE_APPARAISAL_COUNT: "FETCH_INCHARGE_APPARAISAL_COUNT",// only for incharge appraisal count
    FETCH_HOD_APPRAISAL_LIST: "FETCH_HOD_APPRAISAL_LIST",
    FETCH_INCHARGE_APPARAISAL_LIST: "FETCH_INCHARGE_APPARAISAL_LIST",
    FETCH_CEO_APPRAISAL_COUNT: "FETCH_CEO_APPRAISAL_COUNT",
    FETCH_CEO_APPRAISAL_LIST: "FETCH_CEO_APPRAISAL_LIST",
    FETCH_APPRAISAL_COMPLETE: "FETCH_APPRAISAL_COMPLETE",
    FETCH_APPRAISAL_COMPLETE_LIST: "FETCH_APPRAISAL_COMPLETE_LIST",
    FETCH_EMP_PERFORMANCE_ASSESSMNT: "FETCH_EMP_PERFORMANCE_ASSESSMNT",
    FETCH_EMP_COMP_ASSESSMENT: "FETCH_EMP_COMP_ASSESSMENT",
    FETCH_PENDING_APPRAISAL: "FETCH_PENDING_APPRAISAL",
    FETCH_APPRVD_APPRAISAL: "FETCH_APPRVD_APPRAISAL",
    FETCH_TRAINING_PENDING: "FETCH_TRAINING_PENDING",
    FETCH_PROBATION_PENDING: "FETCH_PROBATION_PENDING",
    FETCH_PERAMANENT_PENDING: "FETCH_PERAMANENT_PENDING",
    FETCH_CONTRACT_PENDING: "FETCH_CONTRACT_PENDING",

    FETCH_CONTRACT_APPRAISAL: "FETCH_CONTRACT_APPRAISAL",
    GET_SHIFT_PLAN_DETL: "GET_SHIFT_PLAN_DETL",
    GET_SHIFT_DATE_FORMAT: "GET_SHIFT_DATE_FORMAT",
    FETCH_UPDATED_SHIFT_ID: "FETCH_UPDATED_SHIFT_ID",
    FETCH_UPDATED_PLAN: "FETCH_UPDATED_PLAN",
    FETCH_HOD_INCAHRGE_SECTION: "FETCH_HOD_INCAHRGE_SECTION",
    FETCH_HOD_INCAHRGE_SECT_EMP_NAME: "FETCH_HOD_INCAHRGE_SECT_EMP_NAME",
    FETCH_LEAVE_REQUEST: "FETCH_LEAVE_REQUEST",
    FETCH_COMMON_LEAVES_DATA: "FETCH_COMMON_LEAVES_DATA",
    CHNAGE_REQ_LVE_TYPE: "CHNAGE_REQ_LVE_TYPE",

    FETCH_SINGLE_LEAVE_REQ_FORM_DATA: "FETCH_SINGLE_LEAVE_REQ_FORM_DATA",
    FETCH_EMPLOYEE_INFORMATION_FOR_LVE_REQ: "FETCH_EMPLOYEE_INFORMATION_FOR_LVE_REQ",
    LEAVE_REQ_DEFAULT: "LEAVE_REQ_DEFAULT",
    GET_EMPLOYEE_APPROVAL_LEVEL: "GET_EMPLOYEE_APPROVAL_LEVEL",

    FETCH_MULTI_LEAVE_REQ_FORM_DATA: "FETCH_MULTI_LEAVE_REQ_FORM_DATA",


    //getexceldata to payroll attendace marking
    GET_EXCEL_DATA: "GET_EXCEL_DATA",
    FETCH_EARNDEDUCTION_DATA: "FETCH_EARNDEDUCTION_DATA",
    FETCH_PAYSLIP_TABLEDATA: "FETCH_PAYSLIP_TABLEDATA",

    FETCH_CREDITED_CASUAL_LEAVE_DETL: 'FETCH_CREDITED_CASUAL_LEAVE_DETL',
    FETCH_CREDITED_COMMON_LEAVE: "FETCH_CREDITED_COMMON_LEAVE",
    FETCH_CREDITED_HOLIDAYS_LEAVE: "FETCH_CREDITED_HOLIDAYS_LEAVE",
    FETCH_CREDITED_COMPENSATORY_OFF_LEAVE: "FETCH_CREDITED_COMPENSATORY_OFF_LEAVE",
    FETCH_CREDITED_EARNLEAVE_OFF_LEAVE: "FETCH_CREDITED_EARNLEAVE_OFF_LEAVE",
    FETCH_DUTY_PLANNED_SHIFT_HALF_DAY: "FETCH_DUTY_PLANNED_SHIFT_HALF_DAY",
    GET_SELECTED_EMPLOYEE_LEAVE_REQUEST: 'GET_SELECTED_EMPLOYEE_LEAVE_REQUEST',
    RESET_SELECT_BOX_COUNTER: "RESET_SELECT_BOX_COUNTER",
    FETCH_LEAVE_TYPE_ALL: "FETCH_LEAVE_TYPE_ALL",
    SELECTED_DEPT_VAL: "SELECTED_DEPT_VAL",
    FETCH_DEPT_SECTION_DETL: "FETCH_DEPT_SECTION_DETL",
    FETCH_EMPLOYEE_DETL: "FETCH_EMPLOYEE_DETL",
    FETCH_PUNCH_DATA: "FETCH_PUNCH_DATA",
    //FETCH_SHIFT_DATA: "FETCH_SHIFT_DATA",
    FETCH_PUNCH_MASTER_DATA: "FETCH_PUNCH_MASTER_DATA",
    UPDATE_PUNCHMASTER_TABLE: "UPDATE_PUNCHmASTER_TABLE",
    LOGIN_EMP_DEPTSECT: "LOGIN_EMP_DEPTSECT",
    //leave request approval
    FETCH_ALL_LEAVE_REQUEST: "FETCH_ALL_LEAVE_REQUEST",
    FETCH_ALL_HALFDAY_REQUEST: "FETCH_ALL_HALFDAY_REQUEST",
    FETCH_ALL_NOPUNCH_REQUEST: "FETCH_ALL_NOPUNCH_REQUEST",
    FETCH_ALL_COMPOFF_REQUEST: "FETCH_ALL_COMPOFF_REQUEST",
    //ot request & approval
    FETCH_ALL_OT_INCHARGE: "FETCH_ALL_OT_INCHARGE",
    FETCH_ALL_OT_HOD: "FETCH_ALL_OT_HOD",
    FETCH_ALL_OT_CEO_APPROVAL: 'FETCH_ALL_OT_CEO_APPROVAL',
    FETCH_ALL_OT_HR_APPROVAL: 'FETCH_ALL_OT_HR_APPROVAL',
    FETCH_OT_UPDATION_LIST: 'FETCH_OT_UPDATION_LIST',
    FETCH_EMPLOYEE_UNDER_DEPTSEC: 'FETCH_EMPLOYEE_UNDER_DEPTSEC',
    FETCH_PIN_WISE_REGION: "FETCH_PIN_WISE_REGION",
    FETCH_ONE_HOUR_DATA: "FETCH_ONE_HOUR_DATA",
    FETCH_ON_DUTY_DATA: "FETCH_ON_DUTY_DATA",
    FETCH_ENABLE_MISSPUNCH_DATA: "FETCH_ENABLE_MISSPUNCH_DATA",
    FETCH_GENERAL_RQ_DATA: "FETCH_GENERAL_RQ_DATA",
    FETCH_COMMON_REQUEST: "FETCH_COMMON_REQUEST",
    FETCH_NOTADDED_ESIEMP_LIST: "FETCH_NOTADDED_ESIEMP_LIST",
    FETCH_EMP_ESI_PF_DATA: "FETCH_EMP_ESI_PF_DATA",
    //training module
    FETCH_TRAINING_TYPE_ALL: "FETCH_TRAINING_TYPE_ALL",
    FETCH_SELECT_NEW_JOINERS: "FETCH_SELECT_NEW_JOINERS",
    FETCH_TRAINING_CATEGORY_ALL: "FETCH_TRAINING_CATEGORY_ALL",
    FETCH_TRAINING_NAMES_ALL: " FETCH_TRAINING_NAMES_ALL",
    FETCH_TRAINING_SCHEDULE_ALL: " FETCH_TRAINING_SCHEDULE_ALL",
    FETCH_DEPARTMENTAL_TRAINING_SCHEDULE_ALL: "FETCH_DEPARTMENTAL_TRAINING_SCHEDULE_ALL",
    FETCH_TRAINING_TOPICS_ALL: "FETCH_TRAINING_TOPICS_ALL",
    FETCH_TRAINER_NAMES_ALL: "FETCH_TRAINER_NAMES_ALL",
    SELECTED_EARN_VAL: "SELECTED_EARN_VAL",
    FETCH_EMP_VACCINATION_DETAILS: "FETCH_EMP_VACCINATION_DETAILS",

    //Category
    FETCH_EMPLOYEE_TYPE: "FETCH_EMPLOYEE_TYPE",
    FETCH_DEPT_EMP_NAME: "FETCH_DEPT_EMP_NAME",
    FETCH_TRAINING_PROCESS_ALL: "FETCH_TRAINING_PROCESS_ALL",
    FETCH_DEPARTMENTAL_TRAINING_SCHEDULE: "FETCH_DEPARTMENTAL_TRAINING_SCHEDULE",
    FETCH_DEPT_EMP_NAME_DESG: "FETCH_DEPT_EMP_NAME_DESG",
    FETCH_SCHEDULE_TOPIC_BASED_ON_EMP: "FETCH_SCHEDULE_TOPIC_BASED_ON_EMP",
    FETCH_ALL_QUESTIONS: "FETCH_ALL_QUESTIONS",
    FETCH_ONLINE_TRAINING_DETAILS: "FETCH_ONLINE_TRAINING_DETAILS",
    FETCH_DEPARTMENTAL_SCHEDULED_TOPIC_DPDW: "FETCH_DEPARTMENTAL_SCHEDULED_TOPIC_DPDW",
    FETCH_TRAINING_COMPLETED_LIST: "FETCH_TRAINING_COMPLETED_LIST",
    FETCH_TODAYS_TRAINING__LIST: "FETCH_TODAYS_TRAINING__LIST",
    FETCH_TRAINING_ATTENDANCE_DETAILS: "FETCH_TRAINING_ATTENDANCE_DETAILS",
    FETCH_TRAINING_EMP_DETAILS_ALL: "FETCH_TRAINING_EMP_DETAILS_ALL",
    FETCH_TRAINING_EMP_LIST: "FETCH_TRAINING_EMP_LIST",
    FETCH_TRAINING_POST_TEST_ALLOTED_TO_EMP: "FETCH_TRAINING_POST_TEST_ALLOTED_TO_EMP",
    FETCH_TRAINING_PRETEST_EMP_LIST_ALL: "FETCH_TRAINING_PRETEST_EMP_LIST_ALL",
    FETCH_TRAINING_PRETEST_QR_EMP_DETAILS: "FETCH_TRAINING_PRETEST_QR_EMP_DETAILS",
    FETCH_TRAINING_POSTEST_EMP_LIST_ALL: "FETCH_TRAINING_POSTEST_EMP_LIST_ALL",
    FETCH_TRAINING_POSTTEST_QR_EMP_DETAILS: "FETCH_TRAINING_POSTTEST_QR_EMP_DETAILS",
    FETCH_BELOW_AVERAGE_EMP_LIST: "FETCH_BELOW_AVERAGE_EMP_LIST",
    FETCH_RETEST_EMP_TOPICS_BY_EMID: "FETCH_RETEST_EMP_TOPICS_BY_EMID",
    FETCH_RETEST_QUESTIONS: "FETCH_RETEST_QUESTIONS",
    FETCH_ALL_DEPARTMENT_NAMES: "FETCH_ALL_DEPARTMENT_NAMES",
    FETCH_ALL_DEPARTMENT_SECTION_NAMES: "FETCH_ALL_DEPARTMENT_SECTION_NAMES",
    FETCH_ALL_TOPICS_UNDER_DEPT: "FETCH_ALL_TOPICS_UNDER_DEPT",
    FETCH_TRAINING_TOPIC_BY_DEPT: "FETCH_TRAINING_TOPIC_BY_DEPT",
    FETCH_EMP_COFF_DATA: "FETCH_EMP_COFF_DATA",
    FETCH_COMMON_PRETEST_TOPICS: "FETCH_COMMON_PRETEST_TOPICS",
    FETCH_COMMON_POSTTEST_TOPICS: "FETCH_COMMON_POSTTEST_TOPICS",
    FETCH_NEW_DESIGNATION: "FETCH_NEW_DESIGNATION",
    //induction
    FETCH_NEWJOINEES_LIST: "FETCH_NEWJOINEES_LIST",
    FETCH_TRAINING_TYPE_WISE_TOPICS: "FETCH_TRAINING_TYPE_WISE_TOPICS",
    FETCH_INDUCTION_CALENDER_DETAILS: "FETCH_INDUCTION_CALENDER_DETAILS",
    FETCH__PREPOST_TOPICS: "FETCH__PREPOST_TOPICS",
    FETCH_INDUCTION_TODAY: "FETCH_INDUCTION_TODAY",
    FETCH_INDUCTION_ATTENDACE_DETAILS: "FETCH_INDUCTION_ATTENDACE_DETAILS",
    FETCH_INDUCTION_COMPLETED_LIST: "FETCH_INDUCTION_COMPLETED_LIST",
    FETCH_TRAINING_TEST_EMP_DETAILS: "FETCH_TRAINING_TEST_EMP_DETAILS",
    FETCH_INDUCT_POSTTEST_EMP_DETAILS: "FETCH_INDUCT_POSTTEST_EMP_DETAILS",
    FETCH_INDUCT_PENDING_LIST: "FETCH_INDUCT_PENDING_LIST",
    FETCH_BELOWAVG_EMP_LIST: "FETCH_BELOWAVG_EMP_LIST",
    FETCH_INDUCTION_RETEST_EMP_TOPICS_BY_EMID: "FETCH_INDUCTION_RETEST_EMP_TOPICS_BY_EMID",
    FETCH_INDUCT_RETEST_QUESTIONS: "FETCH_INDUCT_RETEST_QUESTIONS",
    FETCH_INDUCTION_ONLINE_TRAINING_DETAILS: "FETCH_INDUCTION_ONLINE_TRAINING_DETAILS",
    FETCH_INDUCTION_TRAINING_CALENDER_DETAILS_ALL: "FETCH_INDUCTION_TRAINING_CALENDER_DETAILS_ALL",
    FETCH_TRAINING_TOPIC_BY_TYPE: "FETCH_TRAINING_TOPIC_BY_TYPE",
    FETCH_DEPARTMENTAL_TRAININGS: "FETCH_DEPARTMENTAL_TRAININGS",
    FETCH_INDUCTION_TRAININGS: "FETCH_INDUCTION_TRAININGS",
    FETCH_TRAINER_APPRVL_DATA: "FETCH_TRAINER_APPRVL_DATA",
    FETCH_TRAINER_APPRVL_INDUCT_DATA: "FETCH_TRAINER_APPRVL_INDUCT_DATA",

    //leave report
    FETCH_LEAVEREPORT_REQUEST: "FETCH_LEAVEREPORT_REQUEST",
    FETCH_HALFDAY_REQUEST: "FETCH_HALFDAY_REQUEST",
    FETCH_NOPUNCH_REQUEST: "FETCH_NOPUNCH_REQUEST",
    FETCH_COMPOFF_REQUEST: "FETCH_COMPOFF_REQUEST",
    FETCH_ONEHR_REQUEST: "FETCH_ONEHR_REQUEST",
    FETCH_ONDUTY_REQUEST: "FETCH_ONDUTY_REQUEST",
    FETCH_DEPT_PREPOST_QR_DASHBOARD_DATA: "FETCH_DEPT_PREPOST_QR_DASHBOARD_DATA",
    //leave showing table
    FETCH_EACH_EMP_LEAVE_REQUEST: "FETCH_EACH_EMP_LEAVE_REQUEST",
    FETCH_EACH_EMP_HALFDAY_REQUEST: "FETCH_EACH_EMP_HALFDAY_REQUEST",
    FETCH_EACH_EMP_MISSPUNCH_REQUEST: "FETCH_EACH_EMP_MISSPUNCH_REQUEST",
    FETCH_EACH_EMP_COFF_REQUEST: "FETCH_EACH_EMP_COFF_REQUEST",
    FETCH_SECTION_BASED_LEAVE_REQUEST: "FETCH_SECTION_BASED_LEAVE_REQUEST",
    FETCH_SECTION_BASED_HALFDAY_REQUEST: "FETCH_SECTION_BASED_HALFDAY_REQUEST",
    FETCH_SECTION_BASED_MISSPUNCH_REQUEST: "FETCH_SECTION_BASED_MISSPUNCH_REQUEST",
    FETCH_SECTION_BASED_COFF_REQUEST: "FETCH_SECTION_BASED_COFF_REQUEST",
    FETCH_CALENDER_DETAILS: "FETCH_CALENDER_DETAILS",
    FETCH_MONTHWISE_DEPT_SCHEDULE: "FETCH_MONTHWISE_DEPT_SCHEDULE",
    FETCH_TRAINER_DEPT_SEC_NAMES: "FETCH_TRAINER_DEPT_SEC_NAMES",
    FETCH_INDUCTION_TRAINING_TOPICS_ALL: "FETCH_INDUCTION_TRAINING_TOPICS_ALL",
    FETCH_SUBTYPE_ALL: "FETCH_SUBTYPE_ALL",

}